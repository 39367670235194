import PropTypes from "prop-types";
import React from "react";

import { Layout } from "../../components/layout";
import { SEO } from "../../components/meta";
import { ContentObject } from "../../components/content";

import Switch from "./single-service-switch";
import Carousel from "./single-service-carousel";
import Technologies from "./single-service-technologies";
import Projects from "./single-service-projects";
import Estimate from "./single-service-estimate";

const SingleService = ({ location }) => {
  const slug = location.pathname.split(`/`)[2];

  return (
    <>
      <SEO
        title={ContentObject.Services.singleServices[slug].title}
        description={ContentObject.Services.singleServices[slug].caption}
      />

      <Layout header={{ position: "absolute" }} footer={{ size: "compact" }}>
        <section data-csweb={`service-${slug}`}>
          <Switch slug={slug} />
          <Carousel slug={slug} />
          <Technologies slug={slug} />
          <Projects slug={slug} />
          <Estimate slug={slug} />
        </section>
      </Layout>
    </>
  );
};

SingleService.propTypes = {
  location: PropTypes.object,
};

export default SingleService;
