import PropTypes from "prop-types";
import React from "react";
import { navigate } from "gatsby";

import { Grid, Cell, Button } from "../../components/ui";
import {
  ContentItem,
  FormattedContentItem,
  ContentObject,
} from "../../components/content";

import { useStyletron } from "baseui";
import { LabelLarge, HeadingXLarge, ParagraphMedium } from "baseui/typography";

import Illustrations from "../../assets/images/services";

const Carousel = ({ slug }) => {
  const [css, theme] = useStyletron();
  return (
    <article data-csweb={`service-${slug}-carousel`}>
      <Grid>
        <Cell span={12}>
          <div
            className={css({
              background:
                "linear-gradient(135deg, rgba(60,0,220,1) 0%, #284cdf 100%)",
              marginTop: `-${theme.sizing.scale2400}`,
              paddingTop: theme.sizing.scale1400,
              paddingBottom: theme.sizing.scale1600,
              position: "relative",
              [theme.breakpoints.mediaQueries.medium]: {
                paddingTop: theme.sizing.scale800,
                paddingBottom: theme.sizing.scale800,
              },
            })}
          >
            {/* <Button
              kind="circle"
              direction="left"
              className={css({
                //FIXME: sprawdzić pozycję
                position: "absolute !important",
                left: "0px",
                transform: "translate(-50%, -50%)",
                top: "50%",
                zIndex: "2",
                backgroundColor: "white",
              })}
              onClick={() =>
                navigate(
                  `/services/${ContentObject.Services.singleServices[slug].prev}`
                )
              }
            >
              <img
                src={chevronRight}
                className={css({ transform: "rotate(180deg)" })}
                height="14px"
              />
            </Button>
            <Button
              kind="circle"
              className={css({
                position: "absolute !important",
                right: "0px",
                transform: "translate(50%, -50%)",
                top: "50%",
                zIndex: "2",
                backgroundColor: "white",
              })}
              onClick={() =>
                navigate(
                  `/services/${ContentObject.Services.singleServices[slug].next}`
                )
              }
            >
              <img src={chevronRight} height="14px" />
            </Button> */}
            <Grid flex fill alignItems="center">
              <Cell skip={[0, 0, 1]} span={[4, 8, 4]}>
                <HeadingXLarge
                  marginTop="scale0"
                  marginBottom="scale0"
                  $style={{
                    color: theme.colors.white,
                    fontWeight: "600",
                    [theme.breakpoints.mediaQueries.large]: {
                      textAlign: "center",
                      ...theme.typography.DisplayMedium,
                      fontWeight: "bold",
                      marginBottom: theme.sizing.scale1200,
                      paddingLeft: theme.sizing.scale500,
                      paddingRight: theme.sizing.scale500,
                    },
                    [theme.breakpoints.mediaQueries.small]: {
                      ...theme.typography.DisplayXSmall,
                      marginBottom: theme.sizing.scale900,
                    },
                  }}
                >
                  <FormattedContentItem
                    scope="Services"
                    accessor={`singleServices.${slug}.full`}
                  />
                </HeadingXLarge>
              </Cell>
              <Cell span={[4, 8, 4]}>
                <ParagraphMedium
                  color="white"
                  marginTop={["scale1000", "scale0"]}
                  marginBottom="scale0"
                  marginLeft={["scale400", "scale0"]}
                  marginRight={["scale400", "scale0"]}
                  $style={{
                    [theme.breakpoints.mediaQueries.large]: {
                      textAlign: "center",
                      paddingLeft: theme.sizing.scale500,
                      paddingRight: theme.sizing.scale500,
                    },
                  }}
                >
                  <FormattedContentItem
                    scope="Services"
                    accessor={`singleServices.${slug}.caption`}
                  />
                </ParagraphMedium>
              </Cell>
              <Cell span={[4, 8, 2]} aling="center">
                <div
                  className={css({
                    float: "right",
                    marginBottom: theme.sizing.scale600,
                    [theme.breakpoints.mediaQueries.large]: {
                      float: "none",
                      marginBottom: theme.sizing.scale0,
                      textAlign: "center",
                      marginTop: theme.sizing.scale600,
                      paddingLeft: theme.sizing.scale500,
                      paddingRight: theme.sizing.scale500,
                    },
                  })}
                >
                  <Button
                    kind={
                      ContentObject.Services.singleServices[slug].button.kind
                    }
                    label={
                      ContentObject.Services.singleServices[slug].button.label
                    }
                    onClick={() =>
                      navigate(
                        ContentObject.Services.singleServices[slug].button.to
                      )
                    }
                    color="white"
                  >
                    <ContentItem
                      scope="Services"
                      accessor={`singleServices.${slug}.button.title`}
                    />
                  </Button>
                </div>
              </Cell>
              <Cell span={10} skip={1}>
                {ContentObject.Services.singleServices[slug].illustration && (
                  <div
                    className={css({
                      textAlign: "center",
                      paddingTop: theme.sizing.scale2400,
                      [theme.breakpoints.mediaQueries.medium]: {
                        paddingTop: theme.sizing.scale1200,
                      },
                    })}
                  >
                    <img
                      alt="Illustration"
                      src={
                        Illustrations[
                          ContentObject.Services.singleServices[slug]
                            .illustration
                        ]
                      }
                      className={css({ maxWidth: "85%" })}
                    />
                  </div>
                )}
              </Cell>
              <Cell span={[4, 8, 10]} skip={[0, 0, 1]}>
                <div
                  className={css({
                    paddingTop: theme.sizing.scale2400,
                    paddingBottom: theme.sizing.scale600,
                    [theme.breakpoints.mediaQueries.medium]: {
                      paddingTop: theme.sizing.scale1200,
                    },
                  })}
                >
                  <Grid
                    flex
                    flexGridColumnCount={[1, 1, 3, 3]}
                    flexGridColumnGap={["scale100", "scale1000", "scale2400"]}
                    flexGridRowGap={["scale1000", "scale1000", "scale1200"]}
                  >
                    {ContentObject.Services.singleServices[slug].attributes.map(
                      (item, index) => (
                        <Cell key={index} flex>
                          <LabelLarge
                            color="white"
                            $style={{ textAlign: "center" }}
                          >
                            {item.title}
                          </LabelLarge>
                          <ParagraphMedium
                            color="white"
                            $style={{ textAlign: "center" }}
                          >
                            <FormattedContentItem
                              scope="Services"
                              accessor={`singleServices.${slug}.attributes.${index}.caption`}
                            />
                          </ParagraphMedium>
                        </Cell>
                      )
                    )}
                  </Grid>
                </div>
              </Cell>
            </Grid>
          </div>
        </Cell>
      </Grid>
    </article>
  );
};

Carousel.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default Carousel;
