import PropTypes from "prop-types";
import React, { useState, useLayoutEffect } from "react";
import { navigate } from "gatsby";

import { Grid, Cell } from "../../components/ui";
import { ContentObject } from "../../components/content";

import { useStyletron } from "baseui";
import { HeadingLarge, LabelMedium } from "baseui/typography";
import { Select } from "baseui/select";
import { Popover } from "baseui/popover";

import caretDown from "../../assets/images/caret-down.svg";

const Switch = ({ slug }) => {
  const [css, theme] = useStyletron();
  const [switchWidth, setSwitchWidth] = useState(200);

  useLayoutEffect(() => {
    if (typeof window !== "undefined" && document) {
      setSwitchWidth(
        document.querySelector("[data-csweb=ui-switch]").getClientRects()[0]
          .width
      );
      setTimeout(
        () =>
          setSwitchWidth(
            document.querySelector("[data-csweb=ui-switch]").getClientRects()[0]
              .width
          ),
        500
      );
    }
  }, []);

  return (
    <article
      data-csweb={`service-${slug}-switch`}
      className={css({
        backgroundColor: theme.colors.backgroundSecondary,
        paddingTop: theme.sizing.scale4800,
        paddingBottom: theme.sizing.scale4800,
        [theme.breakpoints.mediaQueries.medium]: {
          paddingTop: theme.sizing.scale2400,
          paddingBottom: theme.sizing.scale3200,
        },
      })}
    >
      <Grid>
        <Cell span={12}>
          <Select
            overrides={{
              Root: {
                props: {
                  "data-csweb": "ui-switch",
                },
                style: {
                  width: "max-content",
                  minWidth: "22ch",
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: "75px",
                  ":hover [data-csweb=ui-switch-arrow]": {
                    transform: "translateY(0px)",
                  },
                },
              },
              ControlContainer: {
                style: ({ $isOpen }) => ({
                  transitionDuration: "100ms",
                  transitionProperty: "all",
                  transitionTimingFunction: "ease-in-out",
                  backgroundColor: $isOpen
                    ? theme.colors.backgroundPrimary
                    : "transparent",
                  borderTopLeftRadius: "25px",
                  borderTopRightRadius: "25px",
                  borderBottomLeftRadius: "25px",
                  borderBottomRightRadius: "25px",
                  paddingLeft: theme.sizing.scale500,
                  boxSizing: "border-box",
                  borderTopColor: theme.colors.backgroundSecondary,
                  borderRightColor: theme.colors.backgroundSecondary,
                  borderBottomColor: theme.colors.backgroundSecondary,
                  borderLeftColor: theme.colors.backgroundSecondary,
                  ":hover": {
                    borderTopColor: theme.colors.primary,
                    borderRightColor: theme.colors.primary,
                    borderBottomColor: theme.colors.primary,
                    borderLeftColor: theme.colors.primary,
                  },
                  ...($isOpen && {
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "0px",
                    borderBottomWidth: "0px",
                  }),
                }),
              },
              SingleValue: {
                // eslint-disable-next-line react/prop-types
                component({ children }) {
                  return (
                    <HeadingLarge
                      marginTop="scale200"
                      marginBottom="scale200"
                      marginRight="scale800"
                      $style={{ width: "100%", textAlign: "center" }}
                    >
                      {children}
                    </HeadingLarge>
                  );
                },
              },
              SelectArrow: {
                // eslint-disable-next-line react/prop-types
                component({ $isOpen }) {
                  return (
                    <div
                      className={css({
                        marginRight: theme.sizing.scale400,
                        overflow: "hidden",
                        height: "69px",
                      })}
                    >
                      <div
                        data-csweb="ui-switch-arrow"
                        className={css({
                          transitionDuration: "200ms",
                          transitionProperty: "all",
                          transitionTimingFunction: "ease-in-out",
                          transform: "translateY(-43px)",
                        })}
                      >
                        <img
                          className={css({
                            marginTop: "32px",
                            marginBottom: "32px",
                            display: "block",
                            transitionDuration: "100ms",
                            transitionProperty: "all",
                            transitionTimingFunction: "ease-in-out",
                            transform: $isOpen ? "rotate(180deg)" : "unset",
                          })}
                          src={caretDown}
                          height="11px"
                          alt="Open/Close"
                        />
                        <img
                          className={css({
                            marginTop: "32px",
                            marginBottom: "32px",
                            display: "block",
                            transitionDuration: "100ms",
                            transitionProperty: "all",
                            transitionTimingFunction: "ease-in-out",
                            transform: $isOpen ? "rotate(180deg)" : "unset",
                          })}
                          src={caretDown}
                          height="11px"
                          alt="Open/Close"
                        />
                      </div>
                    </div>
                  );
                },
              },
              Dropdown: {
                style: {
                  marginTop: "-12px",
                  marginLeft: "0px",
                  marginRight: "0px",
                  borderTopWidth: "0px",
                  boxShadow: "none",
                  borderBottomLeftRadius: "25px",
                  borderBottomRightRadius: "25px",
                  borderTopColor: theme.colors.primary,
                  borderRightColor: theme.colors.primary,
                  borderBottomColor: theme.colors.primary,
                  borderLeftColor: theme.colors.primary,
                  backgroundColor: theme.colors.backgroundPrimary,
                  borderTopWidth: "2px",
                  borderRightWidth: "2px",
                  borderBottomWidth: "2px",
                  borderLeftWidth: "2px",
                  borderTopStyle: "solid",
                  borderRightStyle: "solid",
                  borderBottomStyle: "solid",
                  borderLeftStyle: "solid",
                },
              },
              DropdownListItem: {
                // eslint-disable-next-line
                component({ item, children, ...props }) {
                  return (
                    <div
                      className={css({
                        paddingTop: theme.sizing.scale500,
                        paddingBottom: theme.sizing.scale500,
                        width: "100%",
                        ":hover": {
                          backgroundColor: theme.colors.backgroundSecondary,
                          cursor: "pointer",
                        },
                      })}
                      {...props}
                    >
                      <LabelMedium
                        marginTop="scale0"
                        marginBottom="scale0"
                        color="contentAccent"
                        $style={{ width: "100%", textAlign: "center" }}
                      >
                        {item.label}
                      </LabelMedium>
                    </div>
                  );
                },
              },
              Popover: {
                // eslint-disable-next-line
                component: props => {
                  return (
                    <Popover
                      {...props}
                      overrides={{
                        Body: {
                          style: {
                            boxShadow: "unset",
                            backgroundColor: "transparent",
                          },
                        },
                        Inner: {
                          style: {
                            marginLeft: `0px`,
                            width: `${switchWidth}px`,
                            backgroundColor: "transparent",
                          },
                        },
                      }}
                    />
                  );
                },
              },
            }}
            backspaceRemoves={false}
            clearable={false}
            options={[
              ...Object.entries(ContentObject.Services.singleServices)
                .filter(v => v[0] !== slug)
                .map(value => ({
                  label: value[1].short,
                  id: value[0],
                })),
            ]}
            value={{
              label: ContentObject.Services.singleServices[slug].short,
              id: slug,
            }}
            searchable={false}
            placeholder={null}
            onChange={params => navigate(`/services/${params.value[0].id}`)}
          />
        </Cell>
      </Grid>
    </article>
  );
};

Switch.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default Switch;
