import PropTypes from "prop-types";
import React from "react";
import { navigate } from "gatsby";

import { Grid, Cell } from "../../components/ui";
import { ContentObject, FormattedContentItem } from "../../components/content";

import { useStyletron } from "baseui";
import {
  HeadingLarge,
  LabelLarge,
  ParagraphLarge,
  LabelMedium,
} from "baseui/typography";
import { Block } from "baseui/block";

import dotGridBox from "../../assets/images/dot-grid-box.svg";
import dotGridWhiteBox from "../../assets/images/dot-grid-white-box.svg";
import phoneMask from "../../assets/images/phone-mask.svg";
import chevronRightWhite from "../../assets/images/chevron-right-white.svg";
import chevronRightBlack from "../../assets/images/chevron-right-black.svg";

import { useTheme } from "../../contexts/theme";

const Projects = ({ slug }) => {
  const [css, theme] = useStyletron();
  const { useDarkTheme } = useTheme();

  if (
    ContentObject.CaseStudies.items.filter(cs =>
      cs.showInServices.slice(",").includes(slug)
    ).length > 0
  )
    return (
      <article data-csweb={`service-${slug}-projects`}>
        <Grid>
          <Cell skip={6} span={5}>
            <div
              className={css({
                position: "relative",
              })}
            >
              <img
                alt="Dot grid background"
                className={css({
                  position: "absolute",
                  marginTop: "20%",
                  left: "0%",
                  zIndex: "0",
                  pointerEvents: "none",
                })}
                src={useDarkTheme ? dotGridWhiteBox : dotGridBox}
              />
            </div>
          </Cell>
          <Cell skip={[0, 0, 1]} span={[4, 8, 10]}>
            <div
              className={css({
                paddingTop: theme.sizing.scale1600,
                paddingBottom: theme.sizing.scale800,
                position: "relative",
                zIndex: "2",
                [theme.breakpoints.mediaQueries.medium]: {
                  paddingBottom: theme.sizing.scale0,
                  paddingTop: theme.sizing.scale1200,
                },
              })}
            >
              <HeadingLarge
                $style={{
                  textAlign: "center",
                  [theme.breakpoints.mediaQueries.medium]: {
                    paddingBottom: theme.sizing.scale600,
                  },
                }}
                marginBottom="scale100"
              >
                <FormattedContentItem
                  scope="Services"
                  accessor={`singleServices.${slug}.projects.title`}
                />
              </HeadingLarge>
            </div>
          </Cell>
          <Cell skip={[0, 0, 1]} span={[4, 8, 10]}>
            <div
              className={css({
                paddingTop: theme.sizing.scale1200,
                paddingBottom: theme.sizing.scale1200,
                position: "relative",
                zIndex: "2",
              })}
            >
              <Grid
                flex
                flexGridColumnCount={[1, 1, 2, 2]}
                flexGridColumnGap={["scale100", "scale1000", "scale2400"]}
                flexGridRowGap={["scale1000", "scale1000", "scale1200"]}
              >
                {ContentObject.CaseStudies.items
                  .filter(cs => cs.showInServices.slice(",").includes(slug))
                  .map((item, index) => {
                    return (
                      <Cell key={index} flex>
                        <Block
                          onClick={() =>
                            navigate(ContentObject.CaseStudies.items[index].to)
                          }
                          overrides={{
                            Block: {
                              style: {
                                borderTopWidth: "1px",
                                borderRightWidth: "1px",
                                borderBottomWidth: "1px",
                                borderLeftWidth: "1px",
                                borderTopStyle: "solid",
                                borderRightStyle: "solid",
                                borderBottomStyle: "solid",
                                borderLeftStyle: "solid",
                                borderTopColor: theme.colors.servicesCardBorder,
                                borderRightColor:
                                  theme.colors.servicesCardBorder,
                                borderBottomColor:
                                  theme.colors.servicesCardBorder,
                                borderLeftColor:
                                  theme.colors.servicesCardBorder,
                                backgroundColor:
                                  item.kind === "primary"
                                    ? theme.colors.primary
                                    : theme.colors.backgroundPrimary,
                                height: "100%",
                                zIndex: "3",
                                padding: "0px",
                                boxSizing: "border-box",
                                position: "relative",
                                transitionProperty: "all",
                                transitionDuration: "200ms",
                                transitionTimingFunction: "ease-in-out",
                                ":hover": {
                                  boxShadow: `0px 0px 50px 0px ${theme.colors.servicesCardGlow}`,
                                  cursor: "pointer",
                                },
                                ":hover a": {
                                  paddingRight: "20px",
                                },
                                ":hover a::after": {
                                  width: "100%",
                                },
                                ":hover a img": {
                                  opacity: "1",
                                },
                                ":hover [data-csweb=typo-project-lead]": {
                                  ...(item.kind === "primary" && {
                                    opacity: "1",
                                    transform: "translateY(0px)",
                                    paddingBottom: theme.sizing.scale900,
                                  }),
                                  ...(item.kind === "secondary" && {
                                    color: theme.colors.contentPrimary,
                                  }),
                                },
                                ":hover [data-csweb=ui-project-chevron]": {
                                  transform: "translateX(10px)",
                                  [theme.breakpoints.mediaQueries.small]: {
                                    transform: "translateX(2px)",
                                  },
                                },
                                ":hover [data-csweb=ui-project-image]": {
                                  height: "40%",
                                },
                                [theme.breakpoints.mediaQueries.medium]: {
                                  minHeight: "80vw",
                                },
                              },
                            },
                          }}
                        >
                          <div
                            className={css({
                              display: "flex",
                              justifyContent: "center",
                              alignItems: item.inPhoneMask
                                ? "flex-end"
                                : "center",
                              height: "28vw",
                              overflow: "hidden",
                              [theme.breakpoints.mediaQueries.medium]: {
                                minHeight: "80vw",
                              },
                            })}
                          >
                            {item.inPhoneMask ? (
                              <div
                                data-csweb="ui-project-image"
                                className={css({
                                  width: "55%",
                                  overflow: "hidden",
                                  pointerEvents: "none",
                                  zIndex: "4",
                                  position: "relative",
                                  transform: "translateY(40%)",
                                  transitionProperty: "all",
                                  transitionDuration: "200ms",
                                  transitionTimingFunction: "ease-in-out",
                                })}
                              >
                                <img
                                  alt="Phone mask"
                                  src={phoneMask}
                                  width="100%"
                                />
                                <div
                                  className={css({
                                    position: "absolute",
                                    bottom: "-30px",
                                    left: "8%",
                                    top: "8%",
                                    right: "8%",
                                    backgroundColor:
                                      theme.colors.backgroundPrimary,
                                    overflow: "hidden",
                                    borderTopLeftRadius: "30px",
                                    borderTopRightRadius: "30px",
                                    borderBottomRightRadius: "30px",
                                    borderBottomLeftRadius: "30px",
                                    textAlign: "center",
                                    zIndex: "4",
                                  })}
                                >
                                  {ContentObject.CaseStudies.items[index]
                                    .image && (
                                    <img
                                      alt={item.label}
                                      src={require(`../../assets/images/case-studies/${ContentObject.CaseStudies.items[index].slug}/${ContentObject.CaseStudies.items[index].image}`)}
                                      className={css({
                                        transitionProperty: "all",
                                        transitionDuration: "200ms",
                                        transitionTimingFunction: "ease-in-out",
                                        width: "100%",
                                      })}
                                    />
                                  )}
                                </div>
                              </div>
                            ) : (
                              ContentObject.CaseStudies.items[index].image && (
                                <img
                                  alt={item.label}
                                  data-csweb="ui-project-image"
                                  src={require(`../../assets/images/case-studies/${ContentObject.CaseStudies.items[index].slug}/${ContentObject.CaseStudies.items[index].image}`)}
                                  className={css({
                                    transitionProperty: "all",
                                    transitionDuration: "200ms",
                                    transitionTimingFunction: "ease-in-out",
                                    height: "30%",
                                    [theme.breakpoints.mediaQueries.small]: {
                                      marginTop: `-${theme.sizing.scale1600}`,
                                    },
                                  })}
                                />
                              )
                            )}
                          </div>
                          <LabelMedium
                            data-csweb="typo-project-label"
                            $style={{
                              position: "absolute",
                              top: theme.sizing.scale900,
                              right: theme.sizing.scale1000,
                              [theme.breakpoints.mediaQueries.small]: {
                                top: theme.sizing.scale800,
                                right: theme.sizing.scale700,
                              },
                            }}
                            color={
                              item.kind === "primary"
                                ? "white"
                                : "contentPrimary"
                            }
                          >
                            {item.label}
                          </LabelMedium>
                          <div
                            className={css({
                              position: "absolute",
                              left: "0px",
                              bottom: "0px",
                              right: "0px",
                              backgroundColor:
                                item.kind === "primary"
                                  ? theme.colors.primary90
                                  : "transparent",
                              zIndex: "5",
                              paddingLeft: theme.sizing.scale1000,
                              paddingRight: theme.sizing.scale1000,
                              minHeight: "80px",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              [theme.breakpoints.mediaQueries.small]: {
                                paddingLeft: theme.sizing.scale700,
                                paddingRight: theme.sizing.scale700,
                              },
                            })}
                          >
                            <LabelLarge
                              marginTop="scale900"
                              marginBottom="scale100"
                              color={
                                item.kind === "primary"
                                  ? "white"
                                  : "contentPrimary"
                              }
                            >
                              {item.title}
                            </LabelLarge>
                            <ParagraphLarge
                              data-csweb="typo-project-lead"
                              marginTop="scale0"
                              marginBottom="scale0"
                              paddingTop="scale0"
                              paddingRight="scale800"
                              paddingBottom={
                                item.kind === "primary" ? "scale0" : "scale900"
                              }
                              $style={{
                                transitionProperty: "all",
                                transitionDuration: "200ms",
                                transitionTimingFunction: "ease-in-out",
                                ...(item.kind === "primary" && {
                                  opacity: "0",
                                  transform: "translateY(20px)",
                                }),
                                [theme.breakpoints.mediaQueries.small]: {
                                  paddingBottom: theme.sizing.scale600,
                                },
                              }}
                              color={
                                item.kind === "primary" ? "white" : "black30"
                              }
                            >
                              {item.lead}
                            </ParagraphLarge>
                            <img
                              alt="More"
                              data-csweb="ui-project-chevron"
                              src={
                                item.kind === "primary" || useDarkTheme
                                  ? chevronRightWhite
                                  : chevronRightBlack
                              }
                              className={css({
                                position: "absolute",
                                right: theme.sizing.scale1200,
                                bottom: theme.sizing.scale1000,
                                transitionProperty: "all",
                                transitionDuration: "200ms",
                                transitionTimingFunction: "ease-in-out",
                                [theme.breakpoints.mediaQueries.small]: {
                                  right: theme.sizing.scale600,
                                  bottom: theme.sizing.scale800,
                                },
                              })}
                              height="14px"
                            />
                          </div>
                        </Block>
                      </Cell>
                    );
                  })}
              </Grid>
            </div>
          </Cell>
        </Grid>
      </article>
    );
  else return null;
};

Projects.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default Projects;
